$card-height: 500px;
$card-width: 300px;
$card-border-radius: 10px;

.headerWrapper {
    padding-bottom: 5px;
    display: flex;
    justify-content: space-between;
    img {
        width: 13%;
        height: 13%;
    }
}

.appWrapper {
    width: 103%;
    border: solid;
}

.card {
    display: flex;
    justify-content: center;
    height: $card-height;
    align-items: center;

    .swipe {
        position: absolute;
    }
    .cardImg {
        display: flex;
        justify-content: center;
        width: $card-width;
        height: $card-height;
        border-radius: $card-border-radius;
    }
    .bio {
        position: absolute;
        min-height: 30%;
        width: 100%;
        bottom: 0;
        .name {
            padding-top: 100px;
            margin-left: 10px;
            font-size: 1.7em;
            position: relative;
            color: white;
            font-weight: bold;
        }
        .subBio {
            width: 90%;
            display: inline-block;
            padding-left: 15px;
            white-space: initial;
            font-size: .7em;
            bottom: 0;
            color: white;
            font-weight: bold;
        }
        .shadow {
            background: linear-gradient(0deg, rgba(54, 53, 53, 0.59), rgba(255, 255, 255, 0));
            position: absolute;
            border-radius: $card-border-radius;
            bottom: 0;
            width: 100%;
            height: 100%;
        }

    }

    .visibleMSG {
        position: relative;
        overflow: hidden;
        .profileImg {
            width: 40px;
            margin-right: 15px;
            clip-path: circle();
        }

        .DMS {
            //overflow: auto;
            position: fixed;
            top: 0;
            width: 101.9%;
            height: 100%;
            z-index: 2;
            background-color: white;

            .name-msg-line {
                position: absolute;
                top: 0;
                box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.10);
                width: 99%;
                height: 12%;
            }

            .nameTag {
                font-size: 1.5em;
                text-align: center;
            }
            .backBtn {
                width: 15px;
                position: absolute;
                margin-top: 10%;
                z-index: 1;
            }

            .sendMsg {
                position: relative;
                height: 75%;

                .inputField {
                    bottom: 0;
                    position: absolute;
                }

                input {
                    position: absolute;
                    bottom: 0;
                    width: 85%;
                    height: 30px;
                    margin-left: 10%;
                    border-radius: 15px;
                    border-width: .5px;
                    border-color: rgba(190, 189, 189, 0.2);
                    background-color: rgba(229, 229, 234, 0.31);
                    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.10);
                    padding-left: 10px;
                }

                .placeholder {
                    margin-left: 15%;
                    color: #909093;
                    height: 26px;
                    width: 298px;
                }

                span {
                    margin-left: 28%;
                }
            }

        }

        .message {
            margin-left: 5%;
            margin-top: 20%;
            background-color: #e5e5ea;
            border-radius: 10px 15px 15px 5px;
            max-width: 200px;
            padding: 0.5rem 1.5rem;
        }

    }


    .inbox {
        overflow: auto;
        position: absolute;
        width: 101%;
        height: 101%;
        z-index: 1;
        background-color: white;
    }
}

.footerWrapper {
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    img {
        width: 15%;
        height: 15%;
    }
}

.hidden {
    display: none;
}
